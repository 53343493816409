import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@bit/azheng.joshua-tree.button"
import tree from "../images/404tree.svg"

const NotFoundPage = () => (
  <Layout language="en">
    <SEO
      title="404: Page Not Found"
      description="Oops, it looks like the page you’re trying to reach is missing or no longer available. Please check the URL you typed for spelling and capitalization. If you’re still having problems, please visit the homepage."
      robots="noindex"
    />
    <div className="joshua-tree-content">
      <div className="columns is-vcentered">
        <div className="column">
          <div className="columns top-section text-section white-back is-vcentered">
            <div className="column is-2"></div>
            <div className="column is-8">
              {/* <ImageMeta
                          cloudName="nuvolum"
                          publicId="/OSG/DEV/404tree.svg"
                          width="auto"
                        >
                        
                        </ImageMeta> */}
              <img src={tree} />
            </div>
            <div className="column is-12">
              <div className="columns">
                <div className="column has-text-centered-mobile">
                  <h2 className="four04-heading">404: Page Not Found</h2>
                  <h1 className="four04-heading">
                    The desert is really beautiful unless you’re lost.
                  </h1>
                  <p className="large">
                    Oops, it looks like the page you’re trying to reach is
                    missing or no longer available. Please check the URL you
                    typed for spelling and capitalization. If you’re still
                    having problems, please visit the homepage.{" "}
                  </p>
                  <div className="columns has-text-centered-mobile">
                    <div className="column">
                      <Button contained buttonText="Go Home" href="/" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-2"></div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
